/* eslint-disable import/prefer-default-export */

import {
  AD_CREATE,
  CLEAR_AD_LIST,
  AD_MEDIA,
  AD_LIST,
  CLEAR_MEDIA,
  TOTAL_AD,
  AD_LIST_RELATED,
  CLEAR_LIST_ALL,
  AD_MEDIA_RELATED,
  AD_DELETE,
} from '../constants';

export const create = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/ads`, data);
  dispatch({
    type: AD_CREATE,
    payload: response.data,
  });
};

export const get = id => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/ads/${id}`);
  dispatch({
    type: AD_CREATE,
    payload: response.data,
  });
};

export const save = (data, id) => async (dispatch, getState, { axios }) => {
  const response = await axios.put(`/ads/${id}`, data);
  dispatch({
    type: AD_CREATE,
    payload: response.data,
  });
};

export const detachMediaFromApp = (ad, media) => async (dispatch, getState, { axios }) => {
  await axios.delete(`/detach_media/${ad}/${media}`);
}

export const deleteAd = url => async (dispatch, getState, { axios }) => {
  await axios.delete(url);
  dispatch({
    type: AD_DELETE,
    payload: url,
  });
};

export const getAdByUrl = url => async (dispatch, getState, { axios }) => {
  const response = await axios.get(url);
  dispatch({
    type: AD_CREATE,
    payload: response.data,
  });
};

export const clear = () => async dispatch => {
  dispatch({
    type: AD_CREATE,
    payload: null,
  });
};

export const addMedia = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/media_objects`, data);
  dispatch({
    type: AD_MEDIA,
    payload: response.data,
  });
};

export const clearMedia = () => async dispatch => {
  dispatch({
    type: CLEAR_MEDIA,
    payload: null,
  });
};

export const adToMedia = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/ad_media`, data);
  dispatch({
    type: AD_MEDIA_RELATED,
    payload: response.data,
  });
};

export const getList = (
  page = 1,
  filter = null,
  perPage = null,
  search = null,
  related = null,
) => async (dispatch, getState, { axios }) => {
  let additional = '';
  if (filter) {
    if (filter.category)
      additional += `&category=${filter.category}`;
    if (filter.query && filter.query.category)
      additional += `&category=${filter.query.category}`;
    if (filter.query && filter.query.area)
      additional += `&place.municipality.area=${filter.query.area}`;
    if (filter.query && filter.query.municipality)
      additional += `&place.municipality=${filter.query.municipality}`;
    if (filter.query && filter.query.place)
      additional += `&place=${filter.query.place}`;
    if (filter.query && filter.query.phrase)
      additional += `&title=${filter.query.phrase}`;
    if (filter.priceBetween)
      additional += `&price[between]=${Math.min(
        ...filter.priceBetween,
      )}..${Math.max(...filter.priceBetween)}`;
  }
  if (search) {
    additional += `&title=${search}`;
  }
  if (perPage) additional += `&itemsPerPage=${perPage}`;
  // eslint-disable-next-line no-param-reassign
  if (page <= 0) page = 1;
  const response = await axios.get(`/ads?page=${page}${additional}`);
  const type = related
    ? {
        type: AD_LIST_RELATED,
        subType: related,
      }
    : AD_LIST;
  dispatch({
    type,
    payload: response.data,
  });
};

export const getAdsByUser = id => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/ads?user=${id}&pagination=false`);
  dispatch({
    type: AD_LIST,
    payload: response.data,
  });
};

export const clearList = () => async dispatch => {
  dispatch({
    type: CLEAR_AD_LIST,
    payload: null,
  });
};

export const getTotal = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get('/ads?itemsPerPage=0');
  dispatch({
    type: TOTAL_AD,
    payload: response.data,
  });
};

export const clearAll = () => async dispatch => {
  dispatch({
    type: CLEAR_LIST_ALL,
    payload: null,
  });
};

// export const login = data => async dispatch => {
//   try {
//     const response = await axios.post(`/login_check`, data);
//     dispatch({
//       type: USER_LOGIN,
//       payload: response.data,
//     });
//   } catch (e) {
//     dispatch({
//       type: USER_LOGIN,
//       payload: e,
//     });
//   }
// };
//
// export const clearLogin = () => async dispatch => {
//   dispatch({
//     type: USER_LOGIN_CLEAR,
//     payload: null,
//   });
// };
//
// export const getUser = token => async dispatch => {
//   const config = {
//     headers: { Authorization: `bearer ${token}` },
//   };
//   try {
//     const response = await axios.get(`/user`, config);
//     dispatch({
//       type: GET_USER,
//       payload: response.data,
//     });
//   } catch (e) {
//     let data = e;
//     if (e.response.data.message === 'Expired JWT Token') {
//       data = null;
//       dispatch(clearLogin());
//     }
//     dispatch({
//       type: GET_USER,
//       payload: data,
//     });
//   }
// };
//
// export const setLoginData = data => async dispatch => {
//   dispatch({
//     type: USER_LOGIN,
//     payload: data,
//   });
// };
