import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Footer.scss';
import Link from '../Link';
// eslint-disable-next-line import/first
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/first
import { connect } from 'react-redux';
import bs from '../bootstrap.scss';
import { subscribeAction } from '../../actions/subscribe';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscribe: React.createRef(),
    };

    this.handleSubscribe = this.handleSubscribe.bind(this);
  }

  handleSubscribe(e) {
    e.preventDefault();
    // eslint-disable-next-line react/prop-types
    this.props.subscribeAction({
      email: this.state.subscribe.current.value,
    });
  }

  renderSubscribeForm() {
    return (
      <form onSubmit={e => this.handleSubscribe(e)}>
        <input
          ref={this.state.subscribe}
          placeholder="Въведете вашата ел.поща..."
          type="text"
        />
        <button type="submit">Абониране</button>
      </form>
    );
  }

  renderResult() {
    // eslint-disable-next-line react/prop-types
    const { subscribe } = this.props;
    return (
      <div>
        <h4>Резултат:</h4>
        {/* eslint-disable-next-line react/prop-types */}
        <p>{subscribe.message}</p>
      </div>
    );
  }

  renderSubscribe() {
    // eslint-disable-next-line react/prop-types
    const { subscribe } = this.props;
    return (
      <div className={s.subscribe}>
        <div className={s.container}>
          <div>
            {subscribe ? this.renderResult() : this.renderSubscribeForm()}
          </div>
          <div>
            <h4>Абонирайте се!</h4>
            <p>
              Получавате най-новите обяви публикувани в уебсайта преди
              останалите
            </p>
          </div>
        </div>
      </div>
    );
  }

  static renderInfo() {
    return (
      <div className={s.info}>
        <div className={s.description}>
          <div className={s.logo} />
          <p>
            Идеята се породи от надеждата да направим разстоянието между града и
            спокойствието и чистото производство на село, много по-кратко и
            възможно за покоряване.
          </p>
        </div>
        <div className={s.divider} />
        <div>
          <h3>Информация</h3>
          <Link to="/about">За нас</Link>
          <Link to="/terms">Общи условия</Link>
          <Link to="/confidentiality">Поверителност</Link>
          <Link to="/cookies">Политика за бисквитки</Link>
        </div>
        <div className={s.divider} />
        <div>
          <h3>Екстри</h3>
          <Link to="/contact">Контакти</Link>
          <Link to="/register">Регистрация</Link>
        </div>
        <div className={s.divider} />
        <div>
          <h3>За регистрирани</h3>
        </div>
      </div>
    );
  }

  static renderCopyright() {
    const now = new Date();
    return (
      <div className={s.copyright}>
        <p>
          {now.getFullYear()} &#0169; <a href="/">ZSL.bg</a>. Всички права
          запазени. Уебсайт за онлайн обяви.
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className={s.root}>
        {this.renderSubscribe()}
        {Footer.renderInfo()}
        {Footer.renderCopyright()}
      </div>
    );
  }
}

const mapStateToProps = ({ subscribe }) => ({
  subscribe,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ subscribeAction }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(bs, s)(Footer));
