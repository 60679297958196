import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Link from '../../components/Link';
import s from './Breadcrumbs.scss';

class Breadcrumbs extends React.Component {
  getPath() {
    const path = [];
    const { router, ad } = this.props;
    if(!router) return path;
    const { referenceObject } = router;
    if(!referenceObject) return path;
    switch (referenceObject.name) {
      case 'ad':
        if(!ad) return path;
        path.push({name: 'zsl.bg', slug: '/'});
        path.push({name: ad.category.name, slug: ad.category.slug});
        path.push({name: ad.title, slug: null});
        return path;
      default:
        return path;
    }
  }

  render() {
    const path = this.getPath();
    const links = path.map(p => <li key={p.slug}>{p.slug ? <Link to={p.slug}>{p.name}</Link> : <span>{p.name}</span>}</li>);
    return (
      <div className={s.root}>
        <ul>
          {links}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ ad, router }) => ({
  router,
  ad,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Breadcrumbs));
