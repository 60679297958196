/* eslint-disable import/prefer-default-export */

import {
  AREAS_LIST,
  PLACE_SEARCH,
  MUNICIPALITIES_LIST,
  PLACES_LIST,
} from '../constants';

export const getAreas = () => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/geo_areas`);
  dispatch({
    type: AREAS_LIST,
    payload: response.data,
  });
};

export const getMunicipalities = (area) => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/geo_municipalities?area=${area}`);
  dispatch({
    type: MUNICIPALITIES_LIST,
    payload: response.data,
  });
};

export const getPlaces = (municipality) => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/geo_places?municipality=${municipality}`);
  dispatch({
    type: PLACES_LIST,
    payload: response.data,
  });
};

export const searchPlaces = (name) =>async (dispatch, getState, { axios }) => {
  const response = await axios.get(`/geo_places?name=${name}`);
  dispatch({
    type: PLACE_SEARCH,
    payload: response.data,
  });
};

export const clearSearchPlaces = () =>async (dispatch) => {
  dispatch({
    type: PLACE_SEARCH,
    payload: [],
  });
};
