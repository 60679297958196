import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import Cookies from 'js-cookie';
import s from './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Modal from '../Modal/Modal';
import cookiesText from '../../data/static/cookies_popup.md';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    context: PropTypes.shape.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      cookies: props.context.cookie.includes('cookies_accept='),
      client: typeof window !== 'undefined',
    };
    this.client = false;
  }

  componentDidMount() {
    this.client = true;
  }

  accept(name) {
    switch (name) {
      case 'cookies_accept':
        this.setState({ cookies: true });
        Cookies.set('cookies_accept', true, { expires: 365 });
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`${name} not found!`);
    }
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  deny(name) {
    return null;
  }

  renderPopUp() {
    const { cookies, client } = this.state;
    const { route } = this.props.context;
    return (
      <Modal
        header="Политика за бисквитките"
        text={cookiesText.html}
        onAccept={() => this.accept('cookies_accept')}
        acceptText="Разбрах"
        linksFooter={[
          {
            href: '/cookies',
            text: 'Повече информация',
          },
        ]}
        onDeny={() => this.deny('cookies_accept')}
        hidden={!(client && !cookies && route.path !== '/cookies')}
      />
    );
  }

  render() {
    return (
      <div>
        <div className={s.header}>
          <Header context={this.props.context} />
        </div>
        <div className={s.content}>
          <Breadcrumbs />
          {this.props.children}
        </div>
        <div className={s.footer}>
          <Footer />
          {this.renderPopUp()}
        </div>
      </div>
    );
  }
}

export default withStyles(normalizeCss, s)(Layout);
