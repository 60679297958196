exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2e4kC ._26LpG{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5);z-index:100}._2e4kC ._9QpxU{-webkit-box-sizing:border-box;box-sizing:border-box;position:fixed;top:50%;left:50%;width:50%;height:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);background:#fff;border:1px solid #ccc;border-radius:10px;z-index:101;padding:1em}@media screen and (max-width:730px){._2e4kC ._9QpxU{width:90%;height:90%}}._2e4kC ._9QpxU>div{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;height:100%}._2e4kC ._9QpxU>div ._3jVSR{font-size:150%;border-bottom:1px solid #ccc;padding-bottom:.5em;text-transform:uppercase}._2e4kC ._9QpxU>div ._1LnVv{text-align:justify;text-justify:inter-word;font-size:130%}@media screen and (max-width:730px){._2e4kC ._9QpxU>div ._1LnVv{max-height:80%;overflow:scroll}}._2e4kC ._9QpxU>div .JoXC_{border-top:1px solid #ccc;padding-top:1em;display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}._2e4kC ._9QpxU>div .JoXC_ a,._2e4kC ._9QpxU>div .JoXC_ button{padding:1em 4em;border:1px solid #ccc;border-radius:5px}@media screen and (max-width:730px){._2e4kC ._9QpxU>div .JoXC_ a,._2e4kC ._9QpxU>div .JoXC_ button{padding:1em 2em}}", ""]);

// exports
exports.locals = {
	"messageContainer": "_2e4kC",
	"overlay": "_26LpG",
	"modal": "_9QpxU",
	"header": "_3jVSR",
	"content": "_1LnVv",
	"footer": "JoXC_"
};