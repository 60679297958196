import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Select from 'react-select';
import s from './Header.scss';
import Link from '../Link';
import Navigation from '../Navigation';
import { getTotal } from '../../actions/ad';
import { getAreas } from '../../actions/geo';
import { getCategories } from '../../actions/category';
import logo from '../../assets/images/logo.png';
import searchIcon from './image/search-icon.png';

const customStyles = {
  container: base => ({
    // state param
    ...base,
    // minHeight: '1px',
    // maxHeight: '10px',
  }),
  control: provided => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
  }),
  input: provided => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
    backgroundColor: '#ececec',
  }),
  clearIndicator: provided => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
  }),
  valueContainer: provided => ({
    ...provided,
    // minHeight: '1px',
  }),
  singleValue: provided => ({
    ...provided,
    // minHeight: '1px',
    // maxHeight: '10px',
  }),
};

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        searchRef: React.createRef(),
        cityRef: React.createRef(),
        categoryRef: React.createRef(),
      },
      ...this.getSearchObject(),
    };
  }

  getSearchObject() {
    const { context } = this.props;
    return {
      searchValue: context && context.query.phrase ? context.query.phrase : null,
      searchArea: context && context.query.area ? context.query.area : '',
      searchCategory:
        context && context.query.category ? context.query.category : '',
    };
  }

  componentDidMount() {
    const { total } = this.props;
    if (!total.ad) this.props.getTotal();
    this.props.getAreas();
    this.props.getCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.context.query !== this.props.context.query) {
      this.setState(this.getSearchObject());
    }
  }

  getSelectElement(options, name, stateName) {
    let defValue = options[0];
    const selected = this.state[stateName];
    if (selected && parseInt(selected, 10) > 0)
      defValue = options.find(
        o => parseInt(o.value, 10) === parseInt(selected, 10),
      );
    return (
      <Select
        key={this.state[stateName]}
        instanceId={`select-${name}`}
        name={name}
        defaultValue={defValue}
        options={options}
        className={s.select}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#669445',
            primary: '#96de76',
          },
          spacing: {
            ...theme.spacing,
          },
        })}
      />
    );
  }

  render() {
    const { total, categories, areas } = this.props;
    const { search } = this.state;

    const categoryOptions = [
      {
        value: '',
        label: 'Всички категории',
      },
      ...categories.map(c => ({
        value: c.id,
        label: c.name,
      })),
    ];

    const cityOptions = [
      {
        value: '',
        label: 'Всички области',
      },
      ...areas.map(a => ({
        value: a.id,
        label: a.name,
      })),
    ];

    const totalAds = Intl.NumberFormat('bg-BG').format(total.ad);
    const totalText =
      parseInt(total.ad, 10) > 0
        ? {
            __html: `Търси в <span>${totalAds}</span> обяви от цяла България`,
          }
        : {
            __html: '&nbsp;',
          };

    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.logo}>
            <Link to="/" title="ZSL.bg">
              <img src={logo} alt="ZSL.bg" />
            </Link>
          </div>
          <Navigation context={this.props.context} />
        </div>
        <div className={s.header}>
          <h1 dangerouslySetInnerHTML={totalText} />
          <form action="/search">
            <div className={s.search}>
              <label>
                <span>Търсене на обяви</span>
                <input
                  type="text"
                  name="phrase"
                  ref={search.searchRef}
                  defaultValue={this.state.searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                  placeholder="Търсене на обяви"
                />
              </label>
            </div>
            <div className={s.searchBtn}>
              <button type="submit">
                <img src={searchIcon} alt="Търси" />
              </button>
            </div>
            <div className={s.city}>
              <div className={s.label}>
                <span>Област</span>
                {cityOptions.length > 1
                  ? this.getSelectElement(cityOptions, 'area', 'searchArea')
                  : null}
              </div>
            </div>
            <div className={s.category}>
              <div className={s.label}>
                <span>Категория</span>
                {categoryOptions.length > 1
                  ? this.getSelectElement(
                      categoryOptions,
                      'category',
                      'searchCategory',
                    )
                  : null}
              </div>
            </div>
          </form>
          <div className={s.detailedSearch}>
            <Link to="/search">Подробно търсене</Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ user, adList, total, categories, areas }) => ({
  user,
  adList,
  total,
  categories,
  areas,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getTotal, getAreas, getCategories }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Header));
