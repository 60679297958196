exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._5bxoB{width:1200px;margin:1em auto}._5bxoB ul{margin:0;padding:0;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._5bxoB ul li{list-style:none;padding:0;margin:0}._5bxoB ul li:last-child{font-weight:500}._5bxoB ul li:not(:first-child):before{content:\"\\203A\";margin:0 .5em;color:#949494;font-size:150%;line-height:1em;vertical-align:center}._5bxoB ul li a,._5bxoB ul li span{color:#949494;line-height:1em;text-decoration:none}", ""]);

// exports
exports.locals = {
	"root": "_5bxoB"
};