/* eslint-disable import/prefer-default-export */

import {
  SUBSCRIBE,
} from '../constants';

export const subscribeAction = data => async (dispatch, getState, { axios }) => {
  const response = await axios.post(`/subscribe`, data);
  dispatch({
    type: SUBSCRIBE,
    payload: response.data,
  });
};
