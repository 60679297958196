/* eslint-disable import/prefer-default-export */

import {
  CATEGORIES_LIST,
  CATEGORY_ADD,
  CATEGORY_POSITION,
  CATEGORY_DELETE,
  FETCH_CATEGORY,
} from '../constants';

export const fetchCategory = (id) => async (dispatch, getState, { axios }) => {
  const response = await axios.get(`categories/${id}`);
  dispatch({
    type: FETCH_CATEGORY,
    payload: response.data,
  });
};

export const getCategories = token => async (dispatch, getState, { axios }) => {
  let config = null;
  if (token)
    config = {
      headers: { Authorization: `bearer ${token}` },
    };
  const response = await axios.get(`/categories?order[position]=asc`, config);
  dispatch({
    type: CATEGORIES_LIST,
    payload: response.data,
  });
};

export const addCategory = (data, token) => async dispatch => {
  let config = null;
  if (token)
    config = {
      headers: { Authorization: `bearer ${token}` },
    };
  const response = await axios.post(`/categories`, data, config);
  dispatch({
    type: CATEGORY_ADD,
    payload: response.data,
  });
};

export const changePosition = (id, position, token) => async dispatch => {
  let config = null;
  if (token)
    config = {
      headers: { Authorization: `bearer ${token}` },
    };
  const response = await axios.put(id, { position }, config);
  dispatch({
    type: CATEGORY_POSITION,
    payload: response.data,
  });
};

export const deleteCategory = (id, token) => async dispatch => {
  let config = null;
  if (token)
    config = {
      headers: { Authorization: `bearer ${token}` },
    };
  try {
    await axios.delete(id, config);
    dispatch({
      type: CATEGORY_DELETE,
      payload: id,
    });
  } catch (e) {
    dispatch({
      type: CATEGORY_DELETE,
      payload: e,
    });
  }
};
