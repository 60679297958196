/* eslint react/prop-types: 0 */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Navigation.scss';
import Link from '../Link';
import {
  fetchUser,
  login,
  clearLogin,
  logout,
  fetchTest,
} from '../../actions/user';
import { USER_ROLES } from '../../constants';

// Menu items by roles
const ENDPOINTS = {
  ...USER_ROLES,
  ROLE_ADMIN: [
    {
      url: '/category-edit',
      text: 'Редакция категории',
    },
  ],
  ROLE_EDITOR: [],
  ROLE_PUBLISHER: [
    {
      url: '/publish',
      text: 'Добави обява',
    },
    {
      url: '/ad-edit',
      text: 'Редактирай обява',
    },
  ],
  ROLE_USER: [
    {
      url: '/logout',
      text: 'Изход',
    },
  ],
};

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.renderUser = this.renderUser.bind(this);
    this.handleUserMenu = this.handleUserMenu.bind(this);
    this.hideUserMenu = this.hideUserMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setMobileRef = this.setMobileRef.bind(this);
    this.handleOutside = this.handleOutside.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.state = {
      userMenuShow: false,
      profileActive: false,
      login: {
        username: React.createRef(),
        password: React.createRef(),
      },
      mobile: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutside);
    this.props.fetchUser();
    // this.props.fetchTest(); Client certificate authority
  }

  componentDidUpdate(prevProps) {
    const { user, context } = this.props;
    if (context.pathname !== prevProps.context.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ mobile: false });
    }
    if (user.userLogin && user.userLogin.id > 0) {
      this.props.clearLogin();
      this.props.fetchUser();
      this.hideUserMenu();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setMobileRef(node) {
    this.mobileRef = node;
  }

  static renderError(error) {
    if (!error) return '';

    return <div>{error}</div>;
  }

  getAdditional() {
    const additionalActions = [];
    if (this.checkPublish()) {
      additionalActions.push({
        url: '/ad-edit',
        text: 'Редактирай своите обяви',
      });
    }
    return additionalActions;
  }

  checkPublish() {
    const { user } = this.props;
    return (
      user.data && user.data.roles && user.data.roles.includes('ROLE_PUBLISHER')
    );
  }

  checkIsAuth() {
    const { user } = this.props;
    return user.data && !!user.data.id;
  }

  handleUserMenu() {
    this.setState({
      ...this.state,
      userMenuShow: !this.state.userMenuShow,
    });
  }

  hideUserMenu(e) {
    if (e) e.stopPropagation();
    this.setState({
      profileActive: false,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    // eslint-disable-next-line no-shadow
    const { login } = this.state;
    this.props.login({
      username: login.username.current.value,
      password: login.password.current.value,
    });
  }

  handleOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideUserMenu();
    }
    if (this.mobileRef && !this.mobileRef.contains(event.target)) {
      this.setState({ mobile: false });
    }
  }

  renderProfileMenu() {
    const { profileActive } = this.state;

    if (!profileActive) return '';

    const menu = this.checkIsAuth() ? this.renderLogout() : this.renderLogin();

    return <div className={s.profileContainer}>{menu}</div>;
  }

  renderLogout() {
    return (
      <div>
        <div className={s.account}>
          <Link to="/account">Профил</Link>
          <div className={s.break} />
          <button className={s.logoutBtn} onClick={() => this.props.logout()}>
            Изход
          </button>
        </div>
      </div>
    );
  }

  renderLogin() {
    const { user } = this.props;
    // eslint-disable-next-line no-shadow
    const { login } = this.state;
    const error =
      user.userLogin && user.userLogin.error ? user.userLogin.error : null;
    return (
      <form onSubmit={this.handleLogin}>
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>Email</label>
          <input type="text" ref={login.username} />
        </div>
        <div className="form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label>Парола</label>
          <input type="password" ref={login.password} />
        </div>
        {Navigation.renderError(error)}
        <table>
          <tbody>
            <tr>
              <td>
                <Link
                  to="/register"
                  onClick={e => this.hideUserMenu(e)}
                  className={s.registerBtn}
                >
                  Регистрация
                </Link>
              </td>
              <td className="text-right">
                <button type="submit" className={s.loginBtn}>
                  Вход
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <Link to="/forgotten">Забравена парола</Link>
      </form>
    );
  }

  renderUser() {
    const { data } = this.props.user;
    return (
      <div className={s.user}>
        <button
          className="btn btn-primary"
          onClick={() => this.handleUserMenu()}
        >
          {data ? `@${data.userName}` : ''}
        </button>
        {this.renderUserMenu()}
      </div>
    );
  }

  renderUserMenu() {
    if (!this.state.userMenuShow) return '';
    const { data } = this.props.user;
    const menu = data.role
      .map(role => ENDPOINTS[role])
      .reduce((a, b) => [...a, ...b], []);
    return (
      <ul className={s.subMenu}>
        {menu.map(item => (
          <li key={item.url}>
            <Link
              className={s.link}
              to={item.url}
              onClick={() =>
                this.setState({
                  userMenuShow: false,
                })
              }
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  renderAddBtn() {
    const btn = (
      <Link
        to="/publish"
        className={this.checkPublish() ? s.highlightEnable : s.highlightDisable}
        disabled={!this.checkPublish()}
      >
        Добави обява
      </Link>
    );

    const tooltip = !this.checkPublish() ? (
      <span>За да добавите обява в този сайт, моля посетете местното читалище.</span>
    ) : (
      ''
    );

    return (
      <div className={s.highlightContainer}>
        {btn}
        {tooltip}
      </div>
    );
  }

  renderMobile() {
    const { mobile } = this.state;
    if (!mobile) return null;
    return (
      <div ref={this.setMobileRef} className={s.mobile}>
        <button
          className={s.close}
          onClick={() => this.setState({ mobile: false })}
        />
        <Link to="/">Начало</Link>
        <Link to="/about">За нас</Link>
        <Link to="/contact">Контакти</Link>
        {this.getAdditional().map(i => (
          <Link to={i.url}>{i.text}</Link>
        ))}
        {this.renderAddBtn()}
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const { mobile } = this.state;
    return (
      <div className={s.root} role="navigation">
        <button
          onClick={() => this.setState({ mobile: !mobile })}
          type="button"
          className={s.mobileButton}
        />
        <Link className={s.link} to="/">
          Начало
        </Link>
        <Link className={s.link} to="/about">
          За нас
        </Link>
        <Link className={s.link} to="/contact">
          Контакти
        </Link>
        {this.getAdditional().map(i => (
          <Link key={i.url} className={s.link} to={i.url}>
            {i.text}
          </Link>
        ))}
        <div ref={this.setWrapperRef} className={s.profile}>
          <button
            onClick={e => {
              this.setState({ profileActive: !this.state.profileActive });
              e.target.blur();
            }}
          >
            {user && user.data && user.data.name ? user.data.name : 'Моят профил'}
          </button>
          {this.renderProfileMenu()}
        </div>
        {this.renderAddBtn()}
        {this.renderMobile()}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchUser, login, clearLogin, logout, fetchTest },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Navigation));
