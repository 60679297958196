import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Modal.scss';
import Link from '../Link';

class Modal extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    acceptText: PropTypes.string.isRequired,
    linksFooter: PropTypes.array.isRequired,
    hidden: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.hidden) return null;
    return (
      <div className={s.messageContainer}>
        <div className={s.overlay} />
        <div className={s.modal}>
          <div>
            <div className={s.header}>{this.props.header}</div>
            <div
              className={s.content}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: this.props.text }}
            />
            <div className={s.footer}>
              <button onClick={() => this.props.onAccept()}>
                {this.props.acceptText}
              </button>
              {this.props.linksFooter &&
                this.props.linksFooter.map(l => (
                  <Link key={l.href} to={l.href}>
                    {l.text}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Modal);
